import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { redirectToReturnUrl } from '../util/window';
import fetchWithCsrf from '../util/csrf';

function Register() {
  const apiUrl = `${process.env.REACT_APP_API_URL}/register/`;
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [class_ofs, setclass_ofs] = useState<(number | string)[]>([]);
  const [formData, setFormData] = useState({
    username: '',
    displayed_name: '',
    email: '',
    password: '',
    class_of: '0',
    agreement: false,
    confirm_password: '',
  });

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const years = ['不展示', ...Array.from({ length: currentYear - 2020 + 3 }, (_, i) => currentYear + 3 - i), "2020及以前"];
    setclass_ofs(years);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value
    }));
  };

  const validateForm = () => {

    if (formData.username.length < 7) {
      setError("用户名最小长度为7");
      return false;
    }

    if (!/^[a-zA-Z0-9_]+$/.test(formData.username)) {
      setError("用户名只能包含字母、数字及下划线");
      return false;
    }

    if (formData.password.length < 8) {
      setError("密码最小长度为8");
      return false;
    }

    if (formData.password !== formData.confirm_password) {
      setError("两次输入的密码不一致");
      return false;
    }

    if (!formData.agreement) {
      setError("请阅读并同意用户协议及隐私政策");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);
      setError(null);

      try {
        const response = await fetchWithCsrf(apiUrl, {
          method: "POST",
          body: JSON.stringify({
            username: formData.username,
            displayed_name: formData.displayed_name,
            password: formData.password,
            email: formData.email,
            class_of: parseInt(formData.class_of),
            agreement: formData.agreement,
          }),
        });

        const data = await response.json();

        if (!response.ok) {
          switch (response.status) {
            case 400:
            case 409:
              throw new Error(data["message"]);
            default:
              throw new Error(`发生未知错误 (${response.status})`);
          }
        }

        alert('注册成功');
        redirectToReturnUrl('/login');

      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-6 text-center">注册</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="username" className="block mb-1 font-medium">用户名*</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder='7位及以上，注册后无法修改'
            required
          />
        </div>
        <div>
          <label htmlFor="displayed_name" className="block mb-1 font-medium">昵称</label>
          <input
            type="text"
            id="displayed_name"
            name="displayed_name"
            value={formData.displayed_name}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder='默认同用户名，注册后可修改'
          />
        </div>
        <div>
          <label htmlFor="email" className="block mb-1 font-medium">邮箱*</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder='验证后可用于找回密码'
            required
          />
        </div>
        <div>
          <label htmlFor="password" className="block mb-1 font-medium">密码*</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder='8位及以上'
            required
          />
        </div>
        <div>
          <label htmlFor="confirm_password" className="block mb-1 font-medium">确认密码*</label>
          <input
            type="password"
            id="confirm_password"
            name="confirm_password"
            value={formData.confirm_password}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label htmlFor="class_of" className="block mb-1 font-medium">高中毕业年份（公开）*</label>
          <select
            id="class_of"
            name="class_of"
            value={formData.class_of}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          >
            {class_ofs.map((year) => (
              <option key={year} value={year === '不展示' ? 0 : year === '2020及以前' ? 2020 : year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="agreement"
            name="agreement"
            checked={formData.agreement}
            onChange={handleChange}
            className="mr-2"
            required
          />
          <label htmlFor="agreement" className="text-sm">
            我已阅读并同意<a href="/about/user-agreement" className="text-blue-500 hover:underline">用户协议</a>及<a href="/about/privacy-policy" className="text-blue-500 hover:underline">隐私政策</a>
          </label>
        </div>
        <button type="submit" className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-600 transition duration-200" disabled={loading}>
          {loading ? "加载中" : "注册"}
        </button>
        {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
      </form>
      <p className="mt-4 text-center">
        已有账号？ <Link to="/login" className="text-blue-500 hover:underline">登录</Link>
      </p>
    </div>
  );
}

export default Register;
