import { json } from "stream/consumers";

function getCookie(name: string): string | null {
    let cookieValue: string | null = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const csrfApiUrl = `${process.env.REACT_APP_API_URL}/csrf/`;

async function fetchWithCsrf(url: string, options: RequestInit = {}): Promise<any> {

    let csrfToken: string = getCookie('csrftoken') || '';
    options.credentials = 'include';
    options.headers = {
        'X-CSRFToken': csrfToken || '',
    };

    const response = await fetch(url, options);

    if (response.status === 460) {
        try {
            const csrfResponse = await fetch(`${csrfApiUrl}`, {
                method: 'GET',
                credentials: 'include',
            });

            if (csrfResponse.ok) {
                const csrfResponseJson = await csrfResponse.json();
                csrfToken = csrfResponseJson.data.csrfToken;
                document.cookie = `csrftoken=${csrfToken}; path=/;`;

                options.headers['X-CSRFToken'] = csrfToken;

                return fetch(url, options);
            }
            else {
                throw new Error('Unexpected error: cannot get csrf token');
            }
        }
        catch (error: any) {
            throw new Error('Unexpected error: cannot get csrf token');
        }
    }

    return response;
}

export default fetchWithCsrf;