import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { redirectToReturnUrl } from '../util/window';
import fetchWithCsrf from '../util/csrf';

function Login() {
  const apiUrl = `${process.env.REACT_APP_API_URL}/login/`;
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetchWithCsrf(apiUrl, {
        method: "POST",
        body: JSON.stringify({ username, password, remember_me: rememberMe }),
      });

      const data = await response.json();

      if (!response.ok) {
        switch (response.status) {
          case 401:
            throw new Error(data["message"]);
          default:
            throw new Error(`发生未知错误 (${response.status})`);
        }
      }

      alert('登录成功');
      redirectToReturnUrl();

    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-6 text-center">登录</h1>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username" className="block mb-1">用户名</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full px-4 py-2 border rounded"
            required
          />
        </div>
        <div>
          <label htmlFor="password" className="block mb-1">密码</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 border rounded"
            required
          />
        </div>
        <div className='flex items-center space-x-1'>
          <input
            type="checkbox"
            id="remember_me"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <label htmlFor="remember_me" className="block mb-1 text-sm">记住我（14天）</label>
        </div>
        <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600" disabled={loading}>
          {loading ? "加载中" : "登录"}
        </button>
        {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
      </form>
      <p className="mt-4 text-center">
        没有账号？ <Link to="/register" className="text-blue-500 hover:underline">注册</Link>
      </p>
    </div>
  );
}

export default Login;

