import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ThumbsUp, PlusCircle, MessageCircle, ArrowUp, Bookmark, ChevronLeft, ChevronRight } from 'lucide-react';
import fetchWithCsrf from '../util/csrf';
import './Forum.css';

interface Section {
  id: number;
  name: string;
  is_shown_for_user: boolean;
  err_message?: string;
}

interface Post {
  id: number;
  title: string;
  content: string;
  time_created: string;
  time_modified: string;
  num_likes: number;
  num_replies: number;
  num_collects: number;
}

const fetchSections = async (): Promise<Section[]> => {
  const response = await fetchWithCsrf(`${process.env.REACT_APP_API_URL}/forum/sections/`);
  if (!response.ok) {
    throw new Error('网络错误(E1001)');
  }
  const data = await response.json();
  return data.data;
};

const fetchPosts = async (lastPostId: number | null, numRequested: number = 20, sectionName: string | null = null): Promise<Post[]> => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/forum/posts${sectionName ? `/${encodeURIComponent(sectionName)}` : ''}/`);
  if (lastPostId) url.searchParams.append('last_post_id', lastPostId.toString());
  url.searchParams.append('num_requested', numRequested.toString());

  const response = await fetchWithCsrf(url.toString());
  const responseJson = await response.json();
  if (response.ok) {
    return responseJson.data;
  } else {
    if (responseJson.status == 463) {
      throw Error('此内容仅开放给通过基本校友验证的用户');
    } else {
      throw Error('发生未知错误');
    }
  }
};

function formatPublishTime(publishedAt: string): string {
  const now = new Date();
  const publishedDate = new Date(publishedAt);
  const diffInSeconds = Math.floor((now.getTime() - publishedDate.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} day${days > 1 ? 's' : ''} ago`;
  }
}

function Forum() {
  const [selectedSection, setSelectedSection] = useState<number | null>(null);
  const [sections, setSections] = useState<Section[]>([]);
  const [posts, setPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const observer = useRef<IntersectionObserver | null>(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [visibleSections, setVisibleSections] = useState<Section[]>([]);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const sectionContainerRef = useRef<HTMLDivElement>(null);

  const loadPosts = useCallback(async () => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);
    try {
      const lastId = posts.length > 0 ? posts[posts.length - 1].id : null;
      const selectedSectionName = selectedSection !== null ? sections.find(s => s.id === selectedSection)?.name || null : null;
      const newPosts = await fetchPosts(lastId, 20, selectedSectionName);
      if (newPosts.length === 0) {
        setHasMore(false);
      } else {
        setPosts(prevPosts => [...prevPosts, ...newPosts]);
        setPage(prevPage => prevPage + 1);
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [posts, isLoading, hasMore, selectedSection, sections]);

  useEffect(() => {
    fetchSections().then(setSections).catch(() => setError('加载板块失败'));
  }, []);

  useEffect(() => {
    setPosts([]);
    setHasMore(true);
    setPage(1);
    if (selectedSection === null || sections.find(s => s.id === selectedSection)?.is_shown_for_user) {
      setError(null);
      loadPosts();
    }
  }, [selectedSection]);

  const lastPostElementRef = useCallback((node: HTMLDivElement | null) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        loadPosts();
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore, loadPosts]);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSectionClick = (sectionId: number | null) => {
    if (sectionId === selectedSection) return; // Prevent re-selecting the same section
    setSelectedSection(sectionId);
    setPosts([]);
    setHasMore(true);
    setPage(1);
    setError(null);
    
    if (sectionId !== null) {
      const section = sections.find(s => s.id === sectionId);
      if (section && !section.is_shown_for_user) {
        setError(section.err_message || '无法访问此板块');
      }
    }
  };

  useEffect(() => {
    const updateVisibleSections = () => {
      if (sectionContainerRef.current) {
        const containerWidth = sectionContainerRef.current.offsetWidth;
        let totalWidth = 0;
        const visible = [];
        for (const section of sections) {
          const button = sectionContainerRef.current.querySelector(`[data-section-id="${section.id}"]`) as HTMLButtonElement;
          if (button) {
            totalWidth += button.offsetWidth;
            if (totalWidth <= containerWidth) {
              visible.push(section);
            } else {
              break;
            }
          }
        }
        setVisibleSections(visible);
        setShowRightArrow(visible.length < sections.length);
      }
    };

    updateVisibleSections();
    window.addEventListener('resize', updateVisibleSections);
    return () => window.removeEventListener('resize', updateVisibleSections);
  }, [sections]);

  const scrollSections = (direction: 'left' | 'right') => {
    if (sections.length === 0) return;
    
    const currentIndex = sections.findIndex(s => s.id === visibleSections[0].id);
    const newIndex = direction === 'left' ? Math.max(0, currentIndex - 1) : Math.min(sections.length - 1, currentIndex + 1);
    setVisibleSections(sections.slice(newIndex, newIndex + visibleSections.length));
    setShowLeftArrow(newIndex > 0);
    setShowRightArrow(newIndex + visibleSections.length < sections.length);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 relative min-h-screen pb-20">
      <h1 className="text-3xl font-bold mb-6">Forum</h1>
      <div className="relative mb-4">
        <div ref={sectionContainerRef} className="flex overflow-x-auto whitespace-nowrap scrollbar-hide">
          <button
            onClick={() => handleSectionClick(null)}
            className={`flex-shrink-0 px-4 py-2 rounded mr-2 ${selectedSection === null ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            全部
          </button>
          {sections.map(section => (
            <button
              key={section.id}
              data-section-id={section.id}
              onClick={() => handleSectionClick(section.id)}
              className={`flex-shrink-0 px-4 py-2 rounded mr-2 ${selectedSection === section.id ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              {section.name}
            </button>
          ))}
        </div>
        {showLeftArrow && (
          <button
            onClick={() => scrollSections('left')}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-1"
          >
            <ChevronLeft className="h-6 w-6" />
          </button>
        )}
        {showRightArrow && (
          <button
            onClick={() => scrollSections('right')}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-1"
          >
            <ChevronRight className="h-6 w-6" />
          </button>
        )}
      </div>
      <div className="space-y-4 min-h-[200px]">
        {error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <>
            {posts.map((post, index) => (
              <div
                key={post.id}
                ref={index === posts.length - 1 ? lastPostElementRef : null}
                className="border rounded p-4"
              >
                <Link to={`/forum/post/${post.id}`} className="block">
                  <h2 className="text-xl font-semibold mb-2">{post.title}</h2>
                  <p className="text-gray-600 mb-2">{post.content}</p>
                  <div className="flex flex-wrap justify-between items-center text-sm text-gray-500">
                    <div className="flex items-center space-x-4">
                      <span className="flex items-center">
                        <ThumbsUp size={16} className="mr-1" />
                        {post.num_likes}
                      </span>
                      <span className="flex items-center">
                        <MessageCircle size={16} className="mr-1" />
                        {post.num_replies}
                      </span>
                      <span className="flex items-center">
                        <Bookmark size={16} className="mr-1" />
                        {post.num_collects}
                      </span>
                    </div>
                    <div className="flex items-center space-x-4">
                      <span>{formatPublishTime(post.time_created)}</span>
                      {post.time_modified !== post.time_created && (
                        <span className="text-xs">(edited)</span>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
            {isLoading && <p className="text-center">正在加载更多...</p>}
            {!isLoading && !hasMore && posts.length > 0 && <p className="text-center">到底啦</p>}
            {!isLoading && posts.length === 0 && <p className="text-center">暂无帖子</p>}
          </>
        )}
      </div>
      <div className="fixed bottom-4 right-4 space-y-2 z-10">
        <Link
          to="/forum/new-post"
          className="flex items-center justify-center bg-green-500 text-white w-16 h-16 rounded-full hover:bg-green-600 transition-colors duration-200"
        >
          <PlusCircle size={32} />
        </Link>
        {showScrollTop && (
          <button
            onClick={scrollToTop}
            className="flex items-center justify-center bg-gray-500 text-white w-16 h-16 rounded-full hover:bg-gray-600 transition-colors duration-200"
          >
            <ArrowUp size={32} />
          </button>
        )}
      </div>
    </div>
  );
}

export default Forum;

