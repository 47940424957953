import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Search, User, Menu, X } from 'lucide-react';
import fetchWithCsrf from '../util/csrf';
import './Navbar.css';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedin, setIsLoggedin] = useState(false);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/whoami/`;

    const setUser = async () => {
      try {
        const response = await fetchWithCsrf(apiUrl, {
          method: "GET",
        });

        setIsLoggedin(response.ok ? true : false);

      } catch (err: any) {
        console.log(err);
      }
    }

    setUser();
  })

  return (
    <nav className="bg-gray-800 text-white p-4 sticky top-0">
      <div className="container mx-auto">
        <div className="flex items-center justify-between">
          <Link to="/" className="text-xl font-bold">HSYSpace</Link>
          <div className="hidden md:flex items-center space-x-12">
            <Link to="/" className="hover:text-gray-300">主页</Link>
            <Link to="/forum" className="hover:text-gray-300">论坛</Link>
            <Link to="/peak" className="hover:text-gray-300">华一之巅</Link>
            <Link to="/restart" className="hover:text-gray-300">Restart</Link>
            <Link to="/about" className="hover:text-gray-300">关于本站</Link>
          </div>
          <div className="hidden md:flex items-center space-x-4 mr-4">
            <div className="relative mr-4">
              <input
                type="text"
                placeholder="搜索"
                className="bg-gray-700 text-white px-4 py-1 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 h-8"
              />
              <Search className="absolute right-3 top-2 text-gray-400" size={16} />
            </div>
            <Link to={isLoggedin ? "/account" : "/login"} className="user-info-tab hover:text-gray-300 flex items-center">
              <User size={24} className="mr-1" />
              {isLoggedin ? "账户" : "登录"}
            </Link>
          </div>
          <button
            className="md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        {isMenuOpen && (
          <div className="mt-4 md:hidden">
            <div className="flex flex-col space-y-2">
              <Link to="/" className="hover:text-gray-300" onClick={() => setIsMenuOpen(false)}>主页</Link>
              <Link to="/forum" className="hover:text-gray-300" onClick={() => setIsMenuOpen(false)}>论坛</Link>
              <Link to="/peak" className="hover:text-gray-300" onClick={() => setIsMenuOpen(false)}>华一之巅</Link>
              <Link to="/restart" className="hover:text-gray-300" onClick={() => setIsMenuOpen(false)}>Restart</Link>
              <Link to="/about" className="hover:text-gray-300" onClick={() => setIsMenuOpen(false)}>关于本站</Link>
            </div>
            <div className="mt-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="搜索"
                  className="w-full bg-gray-700 text-white px-4 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 h-10"
                />
                <Search className="absolute right-3 top-2.5 text-gray-400" size={20} />
              </div>
            </div>
            <div className="mt-4">
              <Link to={isLoggedin ? "/account" : "/login"} className="hover:text-gray-300 flex items-center" onClick={() => setIsMenuOpen(false)}>
                <User size={24} className="mr-2" />
                {isLoggedin ? "账户" : "登录"}
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;

