import { ShieldQuestion, CircleCheckBig } from 'lucide-react';
import './hover-box.css'

export function VerifiedUserIcon(props: any) {
    return (
        <span className='hover-box' data-hover-text='已认证校友' >
            <CircleCheckBig {...props} color="green" />
        </span>
    );
}

export function UnverifiedUserIcon(props: any) {
    return (
        <span className='hover-box' data-hover-text='未认证用户' >
            <ShieldQuestion {...props} color="brown" />
        </span>
    );
}