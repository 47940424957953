import React from 'react';

function About() {
	return (
		<div>
			<h1>About</h1>
			{/* <h2>Message from contributors</h2> */}
			<div>
				{/* <h3>Message from team head</h3> */}
			</div>
			<div>
				{/* <h3>Message from head of tech</h3> */}
				<p></p>
			</div>
			<h2>Contact us</h2>
			<ol>
				{/* <li>General Affairs: hello@hsyspace.com</li> */}
				<li>Technical Issues: tech@hsyspace.com</li>
			</ol>

			<h2>Contribute to the site / Join us</h2>
			<p>If you have some great ideas about the website, you are welcomed to send us an email.</p>
			<p>If you want to join us, please complete user verification on the account page first. After that, please send us an email using the qq email address that you provided in verification, telling us your real name, graduation year, class number and expected contribution.</p>
			<p>Experience with the things below is a plus. (These are exactly what we are using):</p>
			<ol>
				<li>Frontend: React, NodeJS, Typescript, Javascript (or something similar)</li>
				<li>Backend: Django, Python, MySQL (or something similar)</li>
				<li>Others: Git/Github, Nginx, Linux, Cloudflare</li>
			</ol>
		</div>
	);
}

export default About;
