import React from 'react';
import Navbar from './Navbar';

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  return (
    <div>
      <Navbar />
      <main className="container mx-auto mt-4 px-4">
        {children}
      </main>
    </div>
  );
}

export default Layout;

