import React, { useState, useEffect } from 'react';
import { mapClassOf } from '../util/map';
import { VerifiedUserIcon, UnverifiedUserIcon } from '../components/icons/UserVerification';
import { redirectToReturnUrl } from '../util/window';
import fetchWithCsrf from '../util/csrf';

interface UserPost {
  id: number;
  title: string;
  date: string;
}

function Account() {
  const [username, setUsername] = useState('');
  const [displayedName, setDisplayedName] = useState('')
  const [classofString, setClassofString] = useState('');
  const [isVerifiedUser, setIsVerifiedUser] = useState(false);

  const [profileError, setProfileError] = useState('');

  const [email, setEmail] = useState('');

  const [newEmail, setNewEmail] = useState('');
  const [userPosts, setUserPosts] = useState<UserPost[]>([
    {
      id: 1, title
        : 'My First Post', date: '2023-06-01'
    },
    { id: 2, title: 'Travel Memories', date: '2023-06-15' },
  ]);

  const logout = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/logout/`;

    try {
      const response = await fetchWithCsrf(apiUrl, {
        method: "POST",
      });

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(responseJson["message"])
      }

      alert("已退出登录")
      redirectToReturnUrl();

    } catch (err: any) {
      setProfileError(err.message);
    }
  }

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/whoami/`;

    const setUser = async () => {
      try {
        const response = await fetchWithCsrf(apiUrl, {
          method: "GET",
        });

        const responseJson = await response.json();

        if (!response.ok) {
          throw Error(responseJson["message"])
        }

        const userData = responseJson["data"];

        setUsername(userData.username);
        setDisplayedName(userData.displayed_name);
        setClassofString(mapClassOf(userData.class_of));
        setIsVerifiedUser(userData.is_verified_alumni);

      } catch (err: any) {
        setProfileError(err.message);
      }
    }

    setUser();
  })

  const handleEmailChange = (e: React.FormEvent) => {
    e.preventDefault();
    setEmail(newEmail);
    setNewEmail('');
  };

  return (
    <div className="max-w-2xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">Account</h1>
      <div className="bg-white rounded-lg shadow p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">个人资料</h2>
        <p className='mb-1'>用户名：{username}</p>
        <p className='mb-1'>昵称：{displayedName}</p>
        <p className='mb-1'>邮箱：{email}</p>
        <p className='mb-1'>毕业年份: {classofString}</p>
        <div className="flex items-center space-x-1 mr-4 mb-2">
          <p>认证状态：{isVerifiedUser ? "已认证校友" : "未认证用户"}</p>
          {isVerifiedUser ? (<VerifiedUserIcon size={20} />) : (<UnverifiedUserIcon size={20} />)}
        </div>
        {profileError && <p className="text-red-500 text-sm mt-1">{profileError}</p>}

        <button className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600" onClick={logout}>
          退出登录
        </button>
      </div>
      <div className="bg-white rounded-lg shadow p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">Email Management</h2>
        <p className="mb-4">Current email: {email}</p>
        <form onSubmit={handleEmailChange} className="flex items-center">
          <input
            type="email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            placeholder="New email address"
            className="flex-grow px-4 py-2 border rounded-l"
            required
          />
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-r hover:bg-blue-600">
            Change Email
          </button>
        </form>
      </div>
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold mb-4">Your Posts</h2>
        <ul className="space-y-2">
          {userPosts.map(post => (
            <li key={post.id} className="flex justify-between items-center">
              <span>{post.title}</span>
              <span className="text-sm text-gray-500">{post.date}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Account;

