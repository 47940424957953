import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ThumbsUp, Flag, MessageSquare } from 'lucide-react';

interface Reply {
  id: number;
  content: string;
  author: string;
  likes: number;
  replies: Reply[];
}

interface Post {
  id: number;
  title: string;
  content: string;
  author: string;
  likes: number;
  replies: Reply[];
}

const samplePost: Post = {
  id: 1,
  title: 'Sample Post Title',
  content: 'This is the full content of the sample post. It can be quite long and may contain **markdown** formatting.',
  author: 'John Doe',
  likes: 15,
  replies: [
    {
      id: 1,
      content: 'Great post!',
      author: 'Jane Smith',
      likes: 5,
      replies: [],
    },
    {
      id: 2,
      content: 'I have a question about this.',
      author: 'Bob Johnson',
      likes: 2,
      replies: [
        {
          id: 3,
          content: 'What\'s your question?',
          author: 'John Doe',
          likes: 1,
          replies: [],
        },
      ],
    },
  ],
};

function PostDetails() {
  const { id } = useParams<{ id: string }>();
  const [post, setPost] = useState<Post>(samplePost);

  // In a real application, you would fetch the post data based on the id

  return (
    <div className="max-w-3xl mx-auto px-4">
      <h1 className="text-3xl font-bold mb-4">{post.title}</h1>
      <div className="bg-white rounded-lg shadow p-4 sm:p-6 mb-6">
        <p className="mb-4">{post.content}</p>
        <div className="flex flex-col sm:flex-row sm:items-center justify-between text-sm text-gray-500">
          <span className="mb-2 sm:mb-0">Posted by {post.author}</span>
          <div className="flex items-center space-x-4">
            <button className="flex items-center">
              <ThumbsUp size={20} className="mr-1" />
              {post.likes}
            </button>
            <button className="flex items-center">
              <Flag size={20} className="mr-1" />
              Report
            </button>
          </div>
        </div>
      </div>
      <div className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">Replies</h2>
        {post.replies.map(reply => (
          <div key={reply.id} className="bg-gray-100 rounded-lg p-4 mb-4">
            <p className="mb-2">{reply.content}</p>
            <div className="flex flex-col sm:flex-row sm:items-center justify-between text-sm text-gray-500">
              <span className="mb-2 sm:mb-0">{reply.author}</span>
              <div className="flex items-center space-x-4">
                <button className="flex items-center">
                  <ThumbsUp size={16} className="mr-1" />
                  {reply.likes}
                </button>
                <button className="flex items-center">
                  <Flag size={16} className="mr-1" />
                  Report
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-2">Post a Reply</h3>
        <textarea
          className="w-full p-2 border rounded"
          rows={4}
          placeholder="Write your reply here..."
        ></textarea>
        <div className="flex flex-col sm:flex-row sm:items-center justify-between mt-2">
          <label className="flex items-center mb-2 sm:mb-0">
            <input type="checkbox" className="mr-2" />
            Post anonymously
          </label>
          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            <MessageSquare size={20} className="inline mr-2" />
            Reply
          </button>
        </div>
      </div>
    </div>
  );
}

export default PostDetails;

