import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Forum from './pages/Forum';
import PostDetails from './pages/PostDetails';
import About from './pages/About';
import Login from './pages/Login';
import Register from './pages/Register';
import Account from './pages/Account';
import Error404 from './pages/Error404';

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/forum" element={<Forum />} />
        <Route path="/forum/post/:id" element={<PostDetails />} />

        <Route path="/peak" />

        <Route path="/restart" />

        <Route path="/about" element={<About />} />
        <Route path="/about/user-agreement" />
        <Route path="/about/privacy-policy" />

        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/account" element={<Account />} />

        <Route path="/profile" />
        <Route path="/verify/email" />
        <Route path="/verify/user" />
        <Route path="/verify/reset-password" />

        <Route path="*" element={<Error404 />} />
      </Routes>
    </Layout>
  );
}

export default App;

