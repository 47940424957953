export function getUrlParameter(paramName: string): string | null {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    
    const value = params.get(paramName);
    return value;
}

export function redirectToReturnUrl(defaultUrl: string = `${process.env.REACT_APP_FRONTEND_URL}`) {
    const returnUrl = getUrlParameter("return") || defaultUrl;
    window.location.href = returnUrl;
}